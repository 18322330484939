<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/enroll-form.css";
</style>
<style type="text/css" scoped>
  .custom-file-label::after {
    display: none;
  }
  .msgLabel{
    height: 45px;
  }
  .personPic{
    height: 270px;
  }
  .pay-box img {
    width: 56px;
    height: 56px;
  }
</style>
<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import FormHeader from "@/views/pages/examinee/form-header.vue";
  // import $ from "jquery" 
  import {getUserExamOne} from "@/api/examination"
  /**
   * 报名信息提交
   */
  export default {
    components: {
      Layout,
      PageHeader,
      FormHeader

    },
    data() {
      return {
        title: "报名信息提交",
        items: [{
            text: "资料完善"
          },
          {
            text: "基本信息",
            active: true
          }
        ],
        defaultvalue: 3,
        currentpage: 3,
        zffs:'wx',
        bmzl:{
          xm:'王小川',
          sfzjh:'510101201611222345',
          xb:'女',
          csrq:'1995-12-22',
          mzmc:'汉族',
          zzmmmc:'中共党员',
          jg:'四川南充',
          hkszdmc:'四川南充',
          yddh:'13813881388',
          dzxx:'yethan@qq.com',
          bkdwzy:'四川省高级人民法院（四川省高级人民法院）',
          bkgwxk:'聘用制书记员',
          yjfje:'89.00'
        },
        ksbmbh:'',
        ExamInfo:''
      };
      
    },
    methods: {
      //获取考试报名信息
      getUserExam(){
      getUserExamOne(this.ksbmbh).then(res=>{
        if(res.status){
          this.UserExam= res.data
        }
      })

      },
    },
    created(){
      this.ksbmbh = this.$route.params.ksbmbh
      getExamInfo(this.ksbmbh).then((res) => {
      if (res.status) {
        this.ExamInfo = res.data;
      }
    });
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body">
            <FormHeader :Number="3" :examInfo='ExamInfo' />
            <div class="message-tips"><img src="@/assets/images/person/fa-check-circle.png" class="mr-2">
              <span class="green-font">请再次核对您的报名资料，确认无误后尽快在规定时间内完成缴费。</span>
            </div>
            <div class="outerCotent mt-3 form-sure">
              <input type="hidden" class="msgValInput" value="" name="sid">
              <div class="msgContent flexList fs-xs">
                <div class="flexList col-sm-4 msgLabel">
                  <div class="msgName ">报考单位</div>
                  <div class="msgVal  flexList">
                   <div class="line1">{{bmzl.bkdwzy}} </div>
                  </div>
                </div>
                <div class="flexList col-sm-8 msgLabel">
                  <div class="msgName ">报考职位</div>
                  <div class="msgVal  flexList w-33">
                   <div class="line1 col-sm-4">{{bmzl.bkgwxk}}</div> 
                  </div>
                </div>
                <div class="flexList col-sm-4 msgLabel">
                  <div class="msgName ">姓名</div>
                  <div class="msgVal  flexList">
                   <div class="line1">{{bmzl.xm}}</div>
                  </div>
                </div>
                <div class="flexList col-sm-8 msgLabel">
                  <div class="msgName ">居民身份证/社会保障卡</div>
                  <div class="msgVal  flexList">
                   <div class="line1 col-sm-4">{{bmzl.sfzjh}}</div>
                  </div>
                </div>
                <div class="flexList col-sm-4 msgLabel">
                  <div class="msgName ">性别</div>
                  <div class="msgVal  flexList">
                  <div class="line1">{{bmzl.xb}}</div>
                  </div>
                </div>
                <div class="flexList col-sm-8 msgLabel">
                  <div class="msgName ">出生年月</div>
                  <div class="msgVal  flexList">
                   <div class="line1 col-sm-4">{{bmzl.csrq}}</div>
                  </div>
                </div>
                <div class="flexList col-sm-4 msgLabel">
                  <div class="msgName ">民族</div>
                  <div class="msgVal  flexList">
                    <div class="line1">{bmzl.mzmc}</div>
                    <!-- <input class="msgValInput  col-sm-4" readOnly disabled value=""> -->
                  </div>
                </div>

                <div class="flexList col-sm-8 msgLabel">
                  <div class="msgName ">政治面貌</div>
                  <div class="msgVal  flexList">
                    <div class="line1 col-sm-4" >{{bmzl.zzmmmc}}</div>
                  </div>
                </div>
                <div class="flexList col-sm-4 msgLabel">
                  <div class="msgName ">籍贯</div>
                  <div class="msgVal  flexList">
                  <div class="line1">{{bmzl.jg}}</div>
                  </div>
                </div>

                <div class="flexList col-sm-8 msgLabel">
                  <div class="msgName">户籍所在地</div>
                  <div class="msgVal  flexList">
                    <div class="line1 col-sm-4">{{bmzl.hkszdmc}}</div>
                  </div>
                </div>

                


                <div class="flexList col-sm-4 msgLabel">
                  <div class="msgName ">联系电话</div>
                  <div class="msgVal  flexList">
                   <div class="line1">{{bmzl.yddh}}</div>
                  </div>
                </div>
                <div class="flexList col-sm-8 msgLabel">
                  <div class="msgName ">电子信箱</div>
                  <div class="msgVal  flexList">
                   <div class="line1 col-sm-4">{{bmzl.dzxx}}</div>

                  </div>
                </div>

                <div class="personPic col-sm-4">
                  <img src="@/assets/images/person/person2.png">
                </div>
              </div>

            </div>



          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="pay-money flexList">
              <div class="mr-4">报名费用总额</div>
              <span class="red-font">{{bmzl.yjfje}}元</span>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title border-dash pb-3">请选择支付方式</h4>
            <div class="pay-container p-3">
              <div class="mb-3 d-flex">
                <div class="pay-box active flexList justify-content-center">
                  <div class="custom-control custom-radio  mr-3">
                    <input type="radio" id="customRadio1" name="customRadio" value="wx" class="custom-control-input" v-model="zffs" />
                    <label class="custom-control-label" for="customRadio1">微信支付</label>
                  </div>
                  <img src="@/assets/images/person/antOutline-wechat.png" alt="">
                </div>
                <div class="pay-box flexList justify-content-center">
                  <div class="custom-control custom-radio  mr-3">
                    <input type="radio" id="customRadio2" name="customRadio" value="zfb" class="custom-control-input" v-model="zffs" />
                    <label class="custom-control-label" for="customRadio2">支付宝</label>
                  </div>
                  <img src="@/assets/images/person/antOutline-alipay-circle.png" alt="">
                </div>
                <div class="pay-box flexList justify-content-center">
                  <div class="custom-control custom-radio  mr-3">
                    <input type="radio" id="customRadio3" name="customRadio" value="yb" class="custom-control-input" v-model="zffs" />
                    <label class="custom-control-label" for="customRadio3">易宝</label>
                  </div>
                  <img src="@/assets/images/person/antOutline-yb-circle.jpeg" alt="">
                </div>
              </div>
              <button type="button" class="btn btn-info pay-btn">立即支付</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
